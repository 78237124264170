// signInWithGoogle.ts
import { isSafari, isFirefox, isChrome, isEdge } from "react-device-detect";

import { signInWithRedirect, signInWithPopup } from "firebase/auth";
import { auth, provider } from "@/services/firebase";
const signInWithGoogle = (resolver?: () => void): void => {
  if (isChrome || isEdge) {
    signInWithRedirect(auth, provider, resolver).catch((error) => {
      console.error("Error signing in with Google: ", error);
    });
  } else {
    signInWithPopup(auth, provider, resolver).catch((error) => {
      console.error("Error signing in with Google: ", error);
    });
  }
};
export default signInWithGoogle;
