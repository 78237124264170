const DebtBoxIcon = () => (
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1H17V12H1V1Z"
      fill="#E7ADAD"
      fillOpacity="0.415686"
      stroke="black"
      strokeWidth="1.52294"
    />
    <path
      d="M8.8125 10H6.79261V3.45455H8.90199C9.53693 3.45455 10.0803 3.58558 10.532 3.84766C10.9837 4.1076 11.3299 4.48153 11.5707 4.96946C11.8114 5.45526 11.9318 6.03693 11.9318 6.71449C11.9318 7.39631 11.8104 7.98331 11.5675 8.4755C11.3246 8.96555 10.9709 9.34268 10.5064 9.60689C10.0419 9.86896 9.47727 10 8.8125 10ZM7.58523 9.29688H8.76136C9.30256 9.29688 9.75107 9.19247 10.1069 8.98366C10.4627 8.77486 10.728 8.47763 10.9027 8.09197C11.0774 7.70632 11.1648 7.24716 11.1648 6.71449C11.1648 6.18608 11.0785 5.73118 10.9059 5.34979C10.7333 4.96626 10.4755 4.67223 10.1325 4.46768C9.78942 4.26101 9.36222 4.15767 8.85085 4.15767H7.58523V9.29688Z"
      fill="black"
    />
  </svg>
);

export default DebtBoxIcon;
