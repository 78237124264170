const GovIcon = () => (
  <svg
    width='36'
    height='33'
    viewBox='0 0 36 33'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M18.0516 2L34 32L2 31.9356L18.0516 2Z'
      stroke='black'
      strokeWidth='1.4175'
    />
    <path
      d='M21.1806 19.8651C21.0847 19.5717 20.9584 19.3089 20.8015 19.0765C20.6476 18.8412 20.4631 18.6408 20.2482 18.4752C20.0361 18.3096 19.795 18.1833 19.5249 18.0961C19.2547 18.009 18.9584 17.9654 18.636 17.9654C18.1073 17.9654 17.6266 18.1019 17.1938 18.375C16.761 18.648 16.4168 19.0503 16.1612 19.5819C15.9055 20.1135 15.7777 20.7656 15.7777 21.5383C15.7777 22.3109 15.907 22.9631 16.1655 23.4946C16.424 24.0262 16.7741 24.4285 17.2156 24.7016C17.6571 24.9746 18.1538 25.1111 18.7057 25.1111C19.217 25.1111 19.6672 25.0022 20.0565 24.7844C20.4486 24.5636 20.7536 24.2528 20.9715 23.8519C21.1922 23.4482 21.3026 22.9732 21.3026 22.4271L21.6337 22.4968H18.9497V21.5383H22.3483V22.4968C22.3483 23.2318 22.1915 23.8708 21.8777 24.414C21.5669 24.9572 21.137 25.3784 20.588 25.6776C20.0419 25.9739 19.4145 26.122 18.7057 26.122C17.9156 26.122 17.2214 25.9361 16.623 25.5643C16.0275 25.1925 15.5628 24.6638 15.2287 23.9783C14.8976 23.2928 14.732 22.4794 14.732 21.5383C14.732 20.8324 14.8264 20.1977 15.0152 19.6342C15.2069 19.0678 15.4771 18.5856 15.8257 18.1876C16.1742 17.7897 16.5867 17.4847 17.0631 17.2726C17.5395 17.0606 18.0638 16.9545 18.636 16.9545C19.1066 16.9545 19.5452 17.0257 19.9519 17.168C20.3615 17.3075 20.726 17.5064 21.0455 17.765C21.368 18.0206 21.6366 18.327 21.8516 18.6843C22.0666 19.0387 22.2147 19.4323 22.296 19.8651H21.1806Z'
      fill='black'
      fillOpacity='0.994819'
    />
  </svg>
)

export default GovIcon
