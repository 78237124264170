import { Excalidraw } from "@excalidraw/excalidraw";
import { useEffect, useState } from "react";
import "./MainPage.css";
import TimeTraveller from "@/components/molecules/TimeTraveller";
import { useRecoilState } from "recoil";
import { flowDataState, getCurrentYearData, updateSpecElementInTheScene } from "@/store/flowData.store";
import { cloneObject } from "@/helper/object.helper";
import _ from "lodash";
import { AppState, ExcalidrawImperativeAPI } from "@excalidraw/excalidraw/types/types";
import { ExcalidrawElement } from "@excalidraw/excalidraw/types/element/types";
import Sidebar from "@/components/molecules/Sidebar";
import { excalidrawAppState, updateAppState } from "@/store/appState.store";
import { updateFlagAtom, updateModalOpen } from "@/store/updateFlag.store";

import FinCalculations from "@/services/FinCalculations";
import Menu from "@/components/organisms/Menu";
import StatsBar from "@/components/molecules/StatsBar";
import AssistBox from "@/components/organisms/AssistBox";
import AppToolbar from "@/components/organisms/AppToolbar";
import WelcomeModal from "@/components/molecules/WelcomeModal";
import LogoBar from "@/components/molecules/LogoBar";
import UserAgreement from "@/components/molecules/UserAgreement";
import { hotjar } from "react-hotjar";
import MyCanvases from "@/components/molecules/MyCanvases";

import signInWithGoogle from "@/helper/signInWithGoogle";
import { auth } from "@/services/firebase";

const MainPage = () => {
  hotjar.initialize(3373795, 6);
  hotjar.event("app-loaded");

  const [flowData, setFlowData] = useRecoilState(flowDataState);
  const [excApi, setExcApi] = useState<ExcalidrawImperativeAPI>();
  const [appState, setAppState] = useRecoilState(excalidrawAppState);
  const [openModal, setOpenModal] = useRecoilState(updateModalOpen);
  // const [gridModeEnabled, setGridModeEnabled] = useState(false);
  const [flag, setFlag] = useRecoilState(updateFlagAtom);
  let isReadFromServer = false;

  const handleShowFutureModal = () => {
    console.log(openModal);
    setOpenModal("FutureModal");
    console.log(openModal);
  };

  const handleShowWelcomeModal = () => {
    console.log(openModal);
    setOpenModal("WelcomeModal");
    console.log(openModal);
  };
  const handleShowMyCanvasesModal = () => {
    console.log(openModal);
    setOpenModal("MyCanvasesModal");
    console.log(openModal);
  };

  const handleDateChanged = (els: ExcalidrawElement[]) => {
    // if (!_.isEqual(getCurrentYearData(flowData).elements, els)) {
    console.log("Slider updating excalidraw ...", getCurrentYearData(flowData));
    excApi?.updateScene({
      elements: cloneObject(els),
    });
    // }
  };

  const handleExcalidrawChange = (elements: readonly ExcalidrawElement[], state: AppState) => {
    // determine what has changed in the elements array
    const diff = [];
    const flowDataElements = getCurrentYearData(flowData).elements;
    const flowDataElementsIDs = flowDataElements.map((el) => el.id);
    for (let i = 0; i < elements.length; i++) {
      if (flowDataElementsIDs.includes(elements[i].id)) {
        //element already exists in the flowData, lets see if it is the same:
        if (
          !_.isEqual(
            elements[i],
            flowDataElements.find((el) => el.id === elements[i].id)
          )
        ) {
          diff.push(elements[i]);
        }
      } else {
        //element does not exist in the flowData, lets add it:
        diff.push(elements[i]);
      }
    }
    updateAppState(state, appState, setAppState);
    //@ts-ignore
    if (diff.length > 0) {
      console.log("Excalidraw diff:", diff);
      updateSpecElementInTheScene(cloneObject(elements), cloneObject(diff), state, flowData, setFlowData);
      // remove deleted elements from canvas
      const cleanElements = elements.filter((el) => el.isDeleted === false);
      if (cleanElements.length !== elements.length) excApi?.updateScene({ elements: cleanElements });
      console.log("handleExcalidrawChange", flowData, state);
    }
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
      } else {
        signInWithGoogle();
      }
    });
  }, []);

  return (
    <>
      <LogoBar />
      <AppToolbar
        redrawCanvas={(data) => {
          if (!_.isEqual(getCurrentYearData(flowData).elements, data[0].elements)) {
            excApi?.updateScene({
              elements: cloneObject(data[0].elements),
            });
            isReadFromServer = true;
          }
        }}
        showFutureModal={handleShowFutureModal}
        showWelcomeModal={handleShowWelcomeModal}
        showMyCanvasesModal={handleShowMyCanvasesModal}
      />

      <AssistBox
        redrawCanvas={(data) => {
          if (!_.isEqual(getCurrentYearData(flowData).elements, data[0].elements)) {
            excApi?.updateScene({
              elements: cloneObject(data[0].elements),
            });
            isReadFromServer = true;
          }
        }}
        excApi={excApi}
      />
      <StatsBar />
      <FinCalculations />
      {/*<UpdateElementsTexts*/}
      {/*  // @ts-ignore*/}
      {/*  redrawCanvas={(data) => {*/}
      {/*    if (*/}
      {/*      !_.isEqual(getCurrentYearData(flowData).elements, data[0].elements)*/}
      {/*    ) {*/}
      {/*      excApi?.updateScene({*/}
      {/*        elements: cloneObject(data[0].elements),*/}
      {/*      });*/}
      {/*      isReadFromServer = true;*/}
      {/*    }*/}
      {/*  }}*/}
      {/*/>*/}
      <Menu />
      <Sidebar
        // appState={excApi?.getAppState()}
        selectedToolChanged={(toolType, elementType) => {
          excApi?.setActiveTool({
            type: toolType,
          });
          setFlag("selectedToolChanged");
        }}
      />
      <TimeTraveller
        dateChanged={handleDateChanged}
        redrawCanvas={(data) => {
          if (!_.isEqual(getCurrentYearData(flowData).elements, data[0].elements)) {
            excApi?.updateScene({
              elements: cloneObject(data[0].elements),
            });
            isReadFromServer = true;
            console.log("TimeTraveller redrawCanvas", data[0].elements);
          }
        }}
      />
      <div className="canvas">
        <Excalidraw
          ref={(api: ExcalidrawImperativeAPI) => setExcApi(api)}
          onChange={handleExcalidrawChange}
          UIOptions={{
            canvasActions: {},
            welcomeScreen: false,
          }}
          initialData={getCurrentYearData(flowData)}
        />
      </div>

      <WelcomeModal
        redrawCanvas={(data) => {
          if (!_.isEqual(getCurrentYearData(flowData).elements, data[0].elements)) {
            excApi?.updateScene({
              elements: cloneObject(data[0].elements),
            });
            isReadFromServer = true;
          }
        }}
        // @ts-ignore
        excApi={excApi}
      />
      <MyCanvases
        redrawCanvas={(data) => {
          if (!_.isEqual(getCurrentYearData(flowData).elements, data[0].elements)) {
            excApi?.updateScene({
              elements: cloneObject(data[0].elements),
            });
            isReadFromServer = true;
          }
        }}
      />
      <UserAgreement />
    </>
  );
};

export default MainPage;
