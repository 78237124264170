const RealEstateAssetIcon = () => (
  <svg
    width='35'
    height='23'
    viewBox='0 0 35 23'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M1 1H34V22H1V1Z' stroke='black' strokeWidth='1.52294' />
    <path
      d='M11.0806 16V7.07654H14.0957C14.7929 7.07654 15.3651 7.19563 15.8125 7.43382C16.2598 7.66911 16.5909 7.99299 16.8059 8.40547C17.0208 8.81795 17.1283 9.28707 17.1283 9.81283C17.1283 10.3386 17.0208 10.8048 16.8059 11.2115C16.5909 11.6181 16.2612 11.9377 15.8168 12.1701C15.3724 12.3995 14.8045 12.5143 14.1132 12.5143H11.6731V11.5383H14.0783C14.5547 11.5383 14.9381 11.4686 15.2286 11.3291C15.522 11.1897 15.734 10.9922 15.8647 10.7366C15.9984 10.478 16.0652 10.1701 16.0652 9.81283C16.0652 9.45555 15.9984 9.14328 15.8647 8.87604C15.7311 8.6088 15.5176 8.40257 15.2242 8.25733C14.9309 8.10918 14.5431 8.03511 14.0609 8.03511H12.1612V16H11.0806ZM15.2809 11.9914L17.4769 16H16.222L14.0609 11.9914H15.2809ZM18.9289 16V7.07654H24.3144V8.03511H20.0095V11.0503H24.0355V12.0088H20.0095V15.0414H24.3841V16H18.9289Z'
      fill='black'
      fillOpacity='0.994819'
    />
  </svg>
)

export default RealEstateAssetIcon
