import "./App.css";
import { Route, Routes } from "react-router-dom";

import MainPage from "@/pages/MainPage";
import { hotjar } from "react-hotjar";

function App() {
  hotjar.initialize(3373795, 6);
  if (hotjar.initialized()) {
    hotjar.event("app-loaded");
  }
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }
  return (
    <>
      <Routes>
        <Route path="/">
          <Route path="/" element={<MainPage />} />
          <Route path="/:url" element={<MainPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
