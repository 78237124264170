import { BiUser } from "react-icons/bi";
import { GiArtificialHive } from "react-icons/gi";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { ReactNode } from "react";

interface TimeSeriesItem {
  date: string;

  [key: string]: string;
}

type MessageBoxProps = {
  message: ReactNode;
  className?: string;
  isSender?: boolean;
  chartData?: TimeSeriesItem[];
};

const MessageBox = ({
  message,
  className,
  isSender,
  chartData,
}: MessageBoxProps) => {
  const renderChart = () => {
    if (!chartData) {
      chartData = [];
    }
    if (chartData.length > 0) {
      return (
        <div className="w-full">
          <ResponsiveContainer width="70%" height={250}>
            <LineChart data={chartData}>
              <Line
                type="monotone"
                dataKey="value"
                stroke="#8884d8"
                strokeWidth={2}
                dot={false}
                animationDuration={200}
              />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </div>
      );
    }
    return null;
  };

  const renderMessageWithLinks = () => {
    if (typeof message === "string") {
      const urlRegex = /https?:\/\/[^\s]+/g;
      const parts = message.split(urlRegex);
      const matches = message.match(urlRegex);

      const result = parts.map((part, index) => {
        if (matches && matches[index]) {
          return (
            <>
              {part}
              <a
                href={matches[index]}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                {matches[index]}
              </a>
            </>
          );
        }
        return part;
      });

      return result;
    }
    return message
  };

  return (
    <div
      className={`flex flex-col ${
        isSender ? "items-end" : "items-start"
      } gap-2`}
    >
      <div className={`relative ${className} w-full`}>
        <div
          className="flex flex-col px-2"
          style={{
            minHeight: "32px",
            color: isSender ? "#f0f0f0" : "#333",
            backgroundColor: isSender ? "#3a86ff" : "#edf2f4",
          }}
        >
          <div className="p-4 flex gap-1">
            <div className="w-4 h-4">
              {isSender ? (
                <BiUser color={"#edf2f4"} size={16} />
              ) : (
                <GiArtificialHive color={"#3a86ff"} size={16} />
              )}
            </div>
            <div
              className="text-sm font-regular text-justify"
              style={{
                whiteSpace: "pre-line",
              }}
            >
              {renderMessageWithLinks()}
            </div>
          </div>
          {renderChart()}
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
