import React, { useState, useEffect } from "react";
import { Modal, Button, Input } from "antd";

const UserAgreement: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasAgreed = localStorage.getItem("hasAgreed");
    if (!hasAgreed) {
      setIsOpen(true);
    }
  }, []);

  const handleAgree = () => {
    localStorage.setItem("hasAgreed", "true");
    setIsOpen(false);
  };

  const userAgreement = `
Terms of Use – Financial Planning with Flow

Plan with Flow is an online financial planning visualization software ("the Software") that is a diagnostic and illustrative tool designed to review current financial situation so advisors can suggest potential planning strategies that may be beneficial. It aims to demonstrate how established financial and estate planning principles could improve your situation.
The Software operates based on the information and assumptions provided by you, the advisor. It provides broad and general guidelines on the benefits of certain financial planning concepts and does not constitute a recommendation of any specific technique. The generated report is provided for informational purposes as a courtesy. We recommend reviewing your client's plan annually, or more frequently if their personal or financial circumstances change. All usage should be reviewed in conjunction with the client's fact summary and this Terms of Use page.
The terms "plan" or "planning" within the report do not imply a specific recommendation for implementing one or more financial plans or making a particular investment. The Software and the resulting output do not provide legal, accounting, financial, tax, or other advice. Instead, they offer a summary of potential financial strategies. The reports generate projections based on various assumptions; these are hypothetical and do not guarantee investment returns. Advisors should consult tax and/or legal professionals before implementing any transactions and/or strategies concerning their client's finances.
Please note that the software may not reflect all holdings or transactions, their costs, or proceeds received by the client. It may be missing other factors that may be relevant. As such, those factors will not be included in the projections and plans. Data provided in the software are obtained from sources we consider reliable but are not guaranteed. Past performance does not guarantee future results, and actual results may differ from the projections contained in the report. The presentation of investment returns in this report does not reflect the deduction of any commissions or fees.
The Software utilizes tools such as AI, GPT models and simulations, which yield different results depending on the variables inputted and the underlying assumptions. These simulations perform a mathematical process to implement complex statistical methods, charting the probability of certain financial outcomes at specific times in the future. The outcomes represented by these simulations are only a few of the many possible outcomes. Since past performance and market conditions may not repeat in the future, your client's investment goals may not be fulfilled by following advice based on the projections.
The software should be compared with the statements from the custodian(s) for the client's account(s). Minor variations may occur due to calculation methodologies. If you have any questions, please contact us. Note that client account(s) may not be covered by FDIC or SIPC. FDIC and SIPC coverages apply only to certain assets and may be subject to limitations. Questions about coverage should be directed to the asset provider or sponsor.
The information contained in the Software's is not intended as financial, tax, or legal advice. The information provided should not be relied on for purposes of avoiding any federal tax penalties. Advisors are encouraged to seek financial, tax, and legal advice from professional advisors. By using this Software, you acknowledge that you have read and understood this Terms of Use and, therefore, the limitations of the Software and anything it generates. Furthermore, you understand that none of the calculations and presentations of investment returns are guaranteed.
`;
  return (
    <Modal title="User Agreement" open={isOpen} footer={null} closable={false}>
      <Input.TextArea rows={10} readOnly value={userAgreement} />
      <Button type="primary" onClick={handleAgree} style={{ marginTop: "10px" }}>
        I Agree
      </Button>
    </Modal>
  );
};

export default UserAgreement;
