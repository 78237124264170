import axios from "axios";
import { useRecoilState, useSetRecoilState } from "recoil";
import buildFlowData from "./templateFlowData";
import { flowData } from "@/types/flowData.type";
import { SendOutlined } from "@ant-design/icons";
import { sendEntityRequest } from "./supportFunctions";
import { flowDataState } from "@/store/flowData.store";
import React, { CSSProperties, useEffect, useState } from "react";
import { Button, Card, Input, Modal, Space, Alert, Spin } from "antd";
import { updateFlagAtom, updateModalOpen } from "@/store/updateFlag.store";
import { ExcalidrawImperativeAPI } from "@excalidraw/excalidraw/types/types";
import styled from "styled-components";
import { useParams } from "react-router-dom";

// REGION start, image imports.
import redditIcon from "@/assets/images/reddit.png";
import discordIcon from "@/assets/images/discord.png";
import buyingCar from "@/assets/images/buyingCar.png";
import videoIcon from "@/assets/images/youtube-48.png";
import singleBudget from "@/assets/images/singleBudget.png";
import familyBudget from "@/assets/images/familyBudget.png";
import planningLoan from "@/assets/images/planningLoan.png";
import sharingFunctions from "@/services/sharingFunctions";
import { isMobile } from "react-device-detect";
// REGION end, image imports.

const ChatInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 94%;
`;

const styles: Record<string, CSSProperties> = {
  chatInput: {
    resize: "both",
    paddingRight: "25px",
  },
  sendMessageIcon: {
    position: "absolute",
    right: "10px",
    cursor: "pointer",
  },
};

const urls = [
  {
    url: "https://storage.googleapis.com/planwithflow-templates/simple-cash-flow.fl",
    image: singleBudget,
    title: "Simple Budget Flow",
    description: "Flow of money from income to expense",
  },
  {
    url: "https://storage.googleapis.com/planwithflow-templates/simple-cash-flow.fl",
    image: familyBudget,
    title: "Simple Family Budget",
    description: "Flow of money from income to expense",
  },
  {
    url: "https://storage.googleapis.com/planwithflow-templates/simple-cash-flow.fl",
    image: buyingCar,
    title: "Buying a Car",
    description: "What can you afford? Should you finance it? ",
  },
  {
    url: "https://storage.googleapis.com/planwithflow-templates/simple-cash-flow.fl",
    image: planningLoan,
    title: "Planning a Loan",
    description: "How would getting a loan for a big expense change your finances?",
  },
];

type props = {
  excApi?: ExcalidrawImperativeAPI;
  redrawCanvas: (flowData: flowData) => void;
};

const WelcomeModal = ({ redrawCanvas }: props) => {
  const [flowData, setFlowData] = useRecoilState(flowDataState);
  const setUpdateFlag = useSetRecoilState(updateFlagAtom);
  const [openModal, setOpenModal] = useRecoilState(updateModalOpen);

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(true);
  const [message, setMessage] = useState(
    "Our household has two incomes of $5000, $4500 per month. We have a mortgage of $500K, with interest 3%, a car loan of $40K. Our house is valued at $750K approx. We spend $2500 on living expenses, $2000 on bills and $1000 on travel and entertainment. we have retirement stock portfolio of $200K."
  );
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const showModal = () => {
    setIsWelcomeModalOpen(true);
  };

  //  use useeffect to change iswelcomeModalOpen when isOpen changes
  useEffect(() => {
    if (openModal === "WelcomeModal") showModal();
  }, [openModal]);

  const handleOk = (url: string) => {
    axios.get(url).then((data) => {
      setFlowData(data.data);
      redrawCanvas(data.data);
    });
    setIsWelcomeModalOpen(false);
    setOpenModal("none");
  };

  const { url: paramUrl } = useParams();

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleCancel = async () => {
    setIsWelcomeModalOpen(false);
    setOpenModal("none");

    await sleep(1000); // for the canvas to load
    if (paramUrl) {
      await loadCanvasfromFirebase(paramUrl);
    } else {
      await loadCanvasfromFirebase("d540bb29-76fd-4a30-8a6f-73b7c7490c53");
    }
  };

  const loadCanvasfromFirebase = async (uuid: string) => {
    const flowDataFromFirebase = await sharingFunctions.loadVariableFromFirebase(uuid);
    console.log("flowData in AssistBox: ", flowDataFromFirebase);
    //@ts-ignore
    setFlowData(flowDataFromFirebase);
    console.log("start of redraw");
    //@ts-ignore
    redrawCanvas(flowDataFromFirebase);
    console.log("done", flowDataFromFirebase);

    setUpdateFlag("loadFromFirebase");
  };

  // function for drawing canvas from extracted entities
  const updateCanvas = async () => {
    setLoading(true);
    let extractedEntities: any = {};
    try {
      const res = await sendEntityRequest(message);
      extractedEntities = res.extractedEntities;
      console.log(extractedEntities);
    } catch (err) {
      setLoading(false);
      setShowAlert(true);
    }
    if (typeof extractedEntities === "string") {
      // make an alert about not extracting anything
      setShowAlert(true);
    } else if (typeof extractedEntities === "object") {
      const extractedFlowData = buildFlowData(extractedEntities) as flowData;
      setFlowData(extractedFlowData);
      redrawCanvas(extractedFlowData);

      setUpdateFlag("WelcomeModal");
      setLoading(false);
      setIsWelcomeModalOpen(false);
      setOpenModal("none");
    }
  };

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000);

      // Clear the timer when the component is unmounted or when showAlert is updated
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  return (
    <>
      <Button type="dashed" onClick={showModal} className="hidden">
        Open from URL
      </Button>
      <Modal
        open={isWelcomeModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="lg:min-w-[817px] lg:h-[650px]"
        bodyStyle={{
          padding: isMobile ? "0" : "28px",
          height: "70vh",
        }}
      >
        {/*
          REGION => Mobile content
        */}
        <div className="lg:hidden block h-full overflow-y-auto mt-4">
          <div className="font-medium text-xl">Welcome to Plan with Flow</div>
          <ul className="p-0">
            <li className="font-normal text-base">
              <a
                href="https://www.notion.so/millionmore/A-3-Min-Tutorial-72720432207d4a019adec0a786def155?pvs=4"
                target="_blank"
                rel="noreferrer"
              >
                Quick Tutorial
              </a>
            </li>
            <li className="font-normal text-base">
              <a
                rel="noreferrer"
                href="https://www.notion.so/millionmore/Flow-Help-Center-d08ac48f336e474fb2bcaded20e92b3c?pvs=4"
                target="_blank"
              >
                Flow Help Center
              </a>
            </li>

            <li className="font-normal text-base">
              Join our help community to ask questions and propose new features.{" "}
            </li>

            <li className="font-normal text-base">
              <a
                href="https://www.reddit.com/r/PlanwithFlow/"
                target="_blank"
                rel="noreferrer"
                className="mx-2"
              >
                {" "}
                <img src={redditIcon} alt="reddit-icon" style={{ width: "23px", marginTop: "0px" }} />
              </a>{" "}
              <a href="https://discord.gg/hPhGWPeM6r" target="_blank" rel="noreferrer">
                {" "}
                <img src={discordIcon} alt="discord-icon" style={{ width: "23px" }} />{" "}
              </a>
            </li>
          </ul>

          <div className="font-medium text-xl mt-7 ">Plan with Flow GPT:</div>
          <div className="font-normal text-base mb-2">
            Describe your income, expenses, assets and liabilities in plain language:
          </div>
          <ChatInputContainer>
            <Input.TextArea
              style={styles.chatInput}
              autoSize={{ minRows: 2, maxRows: 4 }}
              value={message}
              placeholder={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                // pressing enter but not holding the shift key
                if (e.key === "Enter" && e.shiftKey === false) {
                  updateCanvas();
                }
              }}
            />
            {loading && <Spin> </Spin>}
            <SendOutlined style={styles.sendMessageIcon} onClick={updateCanvas} />
          </ChatInputContainer>

          <div className="font-normal text-base mt-7">Open a financial model from templates:</div>
          <div className="flex items-center gap-[20px] flex-wrap mt-7">
            {urls.map((data) => (
              <Card
                className="w-full hover:shadow hover:cursor-pointer transition-all"
                // cover={<img height={143} width={215} src={data.image} />}
                onClick={() => handleOk(data.url)}
              >
                {/* @ts-ignore */}
                <Card.Meta
                  title={data.title}
                  description={data.description}
                  style={{ padding: "3px" }}
                ></Card.Meta>
              </Card>
            ))}
          </div>
        </div>

        <div className="overflow-y-scroll lg:h-[99%] h-auto lg:block hidden">
          <div className="font-medium text-xl">Welcome to Plan with Flow</div>
          <ul>
            <li className="font-normal text-base">
              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  href="https://www.notion.so/millionmore/A-3-Min-Tutorial-72720432207d4a019adec0a786def155?pvs=4"
                  target="_blank"
                  rel="noreferrer"
                >
                  Quick Tutorial
                </a>
              </div>
            </li>
            <li className="font-normal text-base">
              <a
                rel="noreferrer"
                href="https://www.notion.so/millionmore/Flow-Help-Center-d08ac48f336e474fb2bcaded20e92b3c?pvs=4"
                target="_blank"
              >
                Flow Help Center
              </a>
            </li>
            <li className="font-normal text-base">
              Join our help community{" "}
              <a
                href="https://www.reddit.com/r/PlanwithFlow/"
                target="_blank"
                rel="noreferrer"
                className="mx-2"
              >
                {" "}
                <img src={redditIcon} alt="reddit-icon" style={{ width: "23px", marginTop: "0px" }} />
              </a>{" "}
              ,{" "}
              <a href="https://discord.gg/hPhGWPeM6r" target="_blank" rel="noreferrer">
                {" "}
                <img src={discordIcon} alt="discord-icon" style={{ width: "23px" }} />{" "}
              </a>
              , to ask questions and propose new features.{" "}
            </li>
          </ul>

          <div className="font-medium text-xl mt-7 ">Plan with Flow GPT:</div>
          <div className="font-normal text-base mb-2">
            Describe your income, expenses, assets and liabilities in plain language:
          </div>
          <ChatInputContainer>
            <Input.TextArea
              style={styles.chatInput}
              autoSize={{ minRows: 2, maxRows: 4 }}
              value={message}
              placeholder={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                // pressing enter but not holding the shift key
                if (e.key === "Enter" && e.shiftKey === false) {
                  updateCanvas();
                }
              }}
            />
            {loading && <Spin> </Spin>}
            <SendOutlined style={styles.sendMessageIcon} onClick={updateCanvas} />
          </ChatInputContainer>

          <div className="font-normal text-base mt-7">Open a financial model from templates:</div>
          <div className="flex items-center gap-[20px] flex-wrap mt-7">
            {urls.map((data) => (
              <Card
                className="w-[215px] hover:shadow hover:cursor-pointer transition-all"
                // cover={<img height={143} width={215} src={data.image} />}
                onClick={() => handleOk(data.url)}
              >
                {/* @ts-ignore */}
                <Card.Meta
                  title={data.title}
                  description={data.description}
                  style={{ padding: "3px" }}
                ></Card.Meta>
              </Card>
            ))}
          </div>
        </div>

        {showAlert && (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Alert
              message="Error"
              description="I could not extract any financial information from your message. Please try again."
              type="error"
              closable
              onClose={() => setShowAlert(false)}
              style={{ zIndex: 1000, position: "relative" }}
            />
          </Space>
        )}
      </Modal>
    </>
  );
};

export default WelcomeModal;
